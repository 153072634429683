@import "reset.scss";
@import "breakpoints.scss";

@font-face {
  font-family: "suisse_intlmedium";
  src: url("./fonts/suisseintl-medium-webfont.woff2") format("woff2"),
    url("./fonts/suisseintl-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "suisse_intlregular";
  src: url("./fonts/suisseintl-regular-webfont.woff2") format("woff2"),
    url("./fonts/suisseintl-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "suisse_intlsemibold";
  src: url("./fonts/suisseintl-semibold-webfont.woff2") format("woff2"),
    url("./fonts/suisseintl-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$bg: #e5e5e5;
$text: #191919;
$subtext: #828282;
$grey: #cecece;
$green: #009b6e;
$yellow: #fd6e3c;
$red: #f53743;

$ease_out: cubic-bezier(0.23, 1, 0.32, 1);
$font-regular: "suisse_intlregular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
$font-medium: "suisse_intlmedium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
$font-semibold: "suisse_intlsemibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;

html,
body {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}
body {
  font-family: $font-regular;
  background: $bg;
  color: $text;
}
#page_overlay {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
* {
  box-sizing: border-box;
}
h1,
h2 {
  font-weight: 900;
}
h1 {
  font-size: 42px;
}
p {
  font-size: 20px;
  orphans: 3;
}

#confettiCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.react-draggable {
  -webkit-transform: translate(0) !important;
  -ms-transform: translate(0) !important;
  transform: translate(0) !important;
  &.react-draggable-dragging {
    cursor: grabbing !important;
  }
}
.scroll-view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // overflow: hidden;
}
.scroll-wrap {
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100%;
  //text-align: center;
}
.scrollIndicator {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: $text;
  z-index: 5;
}
// CSSTransitionGroup Styles

.fade {
  transition: opacity 500ms ease-in-out;
}

.fade-entering {
  opacity: 0;
}

.fade-entered {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exiting {
  opacity: 0;
}

.fade-exited {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

._6733._20ef,
.iOSPWA-container {
  @media (prefers-color-scheme: dark) {
    background: #191919 !important;
  }
}

.scroll-items {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  top: 0;
  overflow: hidden;
  //font-size: 0;
  background-color: $bg;
  &.cloned {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }
  .menuLinks {
    position: fixed;
    top: 1em;
    left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0);
    z-index: 5;
    // writing-mode: vertical-lr;
    a {
      // writing-mode: vertical-lr;
      color: $text;
      text-decoration: none;
      position: relative;
      display: inline-block;
      text-indent: 0;
      margin-right: 1.25em;
      transition: width 0.25s ease;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: $text;
        transition: width 0.35s ease-in-out;
      }
      &:after {
        content: "\2192";
        padding-left: 3px;
        display: inline-block;
        visibility: hidden;
        width: 0;
        opacity: 0;
        transform: translateX(-50%);
        transition: visibility 0s, opacity 0.25s ease-in-out, transform 0.25s ease-in-out, width 0.25s ease;
      }
      &:hover {
        &:before {
          width: 100%;
        }
        &:after {
          width: auto;
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
        @include media("<=phone") {
          &:before {
            width: 0 !important;
          }
          &:after {
            width: 0;
            visibility: hidden;
            opacity: 0;
            transform: none;
          }
        }
      }
    }
  }
  .item {
    // width: 80vw;
    width: auto;
    height: 100vh;
    display: inline-block;
    position: relative;
    overflow: hidden;
    // top: 50%;
    // transform: translate(0, -50%);
    font-size: 16px;
    text-align: left;
    //margin: 0 1.5em 0 0;
    @include media("<=tablet") {
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
    img {
      position: relative;
      z-index: 0;
      height: 100%;
      width: auto;
      min-width: 100%;
      display: block;
      transition: transform 0.6s $ease_out, opacity 0.1s linear;
    }
    .inner {
      padding: 1.5em 1em 1.25em 1em;
      width: auto;
      height: 100vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      // margin-left: 1.5em;
      @include media("<=phone") {
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
      }
      &:after {
        content: "";
        background: $text;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 96.5%;
        width: 1px;
      }
      .canvasContainer {
        width: 80vw;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media("<=phone") {
          width: 95vw;
          &:nth-last-child(1) {
            margin: 0 !important;
          }
        }
        .titles {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          h3 {
            font-family: $font-medium;
            line-height: 1;
          }
          .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .copyright {
            position: absolute;
            bottom: 0;
            right: 0;
            @include media("<=phone") {
              left: 0;
              bottom: 0;
              right: auto;
            }
            h3 {
              font-family: $font-regular;
              color: $subtext;
              line-height: 1;
            }
            a {
              color: $subtext;
              text-decoration: none;
              position: relative;
              display: inline-block;
              text-indent: 0;
              margin-right: 1.25em;
              transition: width 0.25s ease;
              &:nth-last-child(1) {
                margin-right: 0;
              }
              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 0;
                height: 2px;
                background-color: $subtext;
                transition: width 0.35s ease-in-out;
              }
              &:after {
                content: "\2197";
                padding-left: 3px;
                display: inline-block;
                visibility: hidden;
                width: 0;
                opacity: 0;
                transform: translateX(-50%);
                transition: visibility 0s, opacity 0.25s ease-in-out, transform 0.25s ease-in-out, width 0.25s ease;
              }
              &:hover {
                &:before {
                  width: 100%;
                }
                &:after {
                  width: auto;
                  visibility: visible;
                  opacity: 1;
                  transform: translateX(0);
                }
              }
            }
          }
        }
        canvas {
          width: 100%;
          height: 100%;
        }
        &:nth-last-child(1) {
          margin-left: 25px;
          margin-right: 25px;
        }
      }
      .col {
        width: 660px;
        height: 100%;
        margin-right: 3.1em;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        @include media("<=phone") {
          width: 95vw;
          height: 100%;
          &:nth-last-child(1) {
            margin-right: 150px !important;
          }
        }
        p {
          text-indent: 2.5em;
          margin: 0;
          font-size: 20px;
          line-height: 1.4;
          orphans: 3;
          @include media("<=phone") {
            font-size: 16px !important;
            // word-break: break-all;
            hyphens: auto;
            text-indent: 1.5em;
          }
          &.question {
            font-family: $font-medium;
            text-indent: 0px;
            margin-bottom: 1em;
          }
          a {
            text-decoration: none;
            position: relative;
            display: inline-block;
            color: $text;
            text-indent: 0;
            &:after {
              content: "\2197";
              padding-left: 3px;
            }
            &:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 19%;
              width: calc(100% - 18px);
              height: 2px;
              background-color: $text;
              transition: width 0.35s ease-in-out;
            }
            &:hover {
              &:before {
                width: 0;
              }
            }
          }
          span.disclaimer {
            //color: $red;
            font-family: $font-semibold;
          }
        }
        .imageContainer {
          width: 100%;
          height: 410px;
          overflow: hidden;
          background: $subtext;
          position: relative;
          margin-top: 2em;
          background-size: cover;
          @include media("<=phone") {
            height: 240px;
            margin-top: 1em;
          }
        }
        &:nth-last-child(1) {
          margin-right: 300px;
        }
        &.actionItems {
          width: auto;
          height: auto;
          display: grid;
          grid-auto-flow: column;
          grid-template-rows: repeat(2, max-content);
          align-items: end;
          grid-gap: 2.5em;
          @include media("<=phone") {
            grid-template-rows: repeat(1, auto);
            grid-gap: 1em;
          }
        }
        .action {
          width: 660px;
          max-height: calc(100vh - 2em);
          @include media("<=phone") {
            width: 95vw;
          }
          .exp {
            display: table; // to center the item
            width: 100%;
            height: 100%;
            .checkbox {
              display: table-cell; // to center the item
              width: 100%;
              height: 100%;
              vertical-align: middle; // to center the item
            }
          }
          label {
            display: inline-block; // to make it easier to click
            font-family: $font-medium;
            font-size: 20px;
            color: $text;
            cursor: pointer;
            position: relative; // important
            padding-bottom: 0.4em;
            p {
              display: inline;
              position: relative;
              orphans: 3;
              &:after {
                content: " ";
                position: absolute;
                top: 40%;
                left: 0;
                width: 0%;
                height: 2px;
                background: $text;
                transition: width 0.5s ease-in-out;
              }
            }
            span {
              display: inline-block;
              position: relative;
              background-color: transparent;
              width: 25px;
              height: 25px;
              transform: scale(1.1);
              transform-origin: center;
              border: 1.5px solid $text;
              border-radius: 50%;
              vertical-align: -6px;
              margin-right: 10px;
              transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89); // custom ease effect for bouncy animation
              &:before {
                content: "";
                width: 0px;
                height: 2px;
                border-radius: 2px; // so that the tick has nice rounded look
                background: $text;
                position: absolute;
                transform: rotate(45deg);
                top: 11px; // you'll need to experiment with placement depending on the dimensions you've chosen
                left: 7px; // you'll need to experiment with placement depending on the dimensions you've chosen
                transition: width 50ms ease 50ms;
                transform-origin: 0% 0%;
              }
              &:after {
                content: "";
                width: 0;
                height: 2px;
                border-radius: 2px; // so that the tick has nice rounded look
                background: $text;
                position: absolute;
                transform: rotate(305deg);
                top: 14px; // you'll need to experiment with placement depending on the dimensions you've chosen
                left: 8px; // you'll need to experiment with placement depending on the dimensions you've chosen
                transition: width 50ms ease;
                transform-origin: 0% 0%;
              }
            }
            &:hover {
              span {
                &:before {
                  width: 5px;
                  transition: width 100ms ease;
                }
                &:after {
                  width: 10px;
                  transition: width 150ms ease 100ms;
                }
              }
            }
          }
          input[type="checkbox"] {
            display: none; // hide the system checkbox
            &:checked {
              + label {
                p {
                  &:after {
                    width: 100%;
                  }
                }
                span {
                  background-color: $text;
                  transform: scale(1.1); // enlarge the box

                  &:after {
                    width: 10px;
                    background: $bg;
                    transition: width 150ms ease 100ms; // enlarge the tick
                  }

                  &:before {
                    width: 5px;
                    background: $bg;
                    transition: width 150ms ease 100ms; // enlarge the tick
                  }
                }
                &:hover {
                  // copy the states for onMouseOver to avoid flickering
                  span {
                    background-color: $text;
                    transform: scale(1.1); // enlarge the box

                    &:after {
                      width: 10px;
                      background: $bg;
                      transition: width 150ms ease 100ms; // enlarge the tick
                    }
                    &:before {
                      width: 5px;
                      background: $bg;
                      transition: width 150ms ease 100ms; // enlarge the tick
                    }
                  }
                }
                + .actionInfo {
                  opacity: 0.5;
                }
              }
            }
          }
          .actionInfo {
            display: flex;
            flex-direction: column;
            width: calc(100% - 2.2em);
            height: auto;
            margin-left: 2.2em;
            justify-content: flex-end;
            align-items: flex-end;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            @include media("<=phone") {
              margin: 0;
            }
            .desc {
              color: $subtext;
              padding-bottom: 25px;
              a {
                color: $subtext;
                &:before {
                  background: $subtext;
                }
              }
            }
            .icons {
              display: flex;
              width: 100%;
              height: auto;
              justify-content: flex-start;
              align-items: flex-start;
              @include media("<=phone") {
                flex-wrap: wrap;
              }
              a {
                text-decoration: none;
                color: $text;
              }
              .actionItem {
                width: auto;
                height: auto;
                margin-right: 3.125em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @include media("<=phone") {
                  margin-right: 2.2em;
                  margin-bottom: 2.2em;
                  align-items: flex-start;
                  &:nth-last-child(-n + 2) {
                    margin-bottom: 0;
                  }
                }
                .iconContainer {
                  width: 70px;
                  height: 70px;
                  border-radius: 7px;
                  overflow: hidden;
                  background-size: cover;
                  @include media("<=phone") {
                    width: 50px;
                    height: 50px;
                  }
                }
                .label {
                  font-size: 16px;
                  line-height: 1;
                  padding-top: 12px;
                  @include media("<=phone") {
                    font-size: 14px;
                  }
                }
                &:nth-last-child(1) {
                  margin-right: 0;
                }
              }
            }
          }
        }
        //Portrait
        &.portrait {
          width: auto;
          position: relative;
          &:nth-last-child(1) {
            margin-right: 300px;
          }
          @include media("<=phone") {
            width: 95vw;
          }
        }
        .split {
          width: 100%;
          min-width: 800px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          @include media("<=phone") {
            min-width: auto;
            width: 95vw;
          }
          .top {
            width: 72.44%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: flex-start;
            .line {
              display: flex;
              flex-direction: column;
              justify-content: center;
              &:nth-last-child(1) {
                align-items: flex-end;
                padding-bottom: 0.2em;
              }
              width: 100%;
              height: auto;
              font-size: 125px;
              line-height: 1;
              @include media("<=phone") {
                font-size: 86px;
              }
            }
            transition: all 500ms ease-in-out;
          }
          .bottom {
            width: 100%;
            height: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            text-align: right;
            .caption {
              width: 100%;
              height: 5%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-self: center;
              p {
                font-size: 18px;
                line-height: 1;
              }
            }
            .dnd {
              width: 100%;
              height: 95%;
              position: relative;
              .container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                cursor: pointer;
                svg {
                  width: 100px;
                  height: auto;
                }
                p {
                  &.accepted {
                    color: $green;
                  }
                  &.warning {
                    color: $yellow;
                  }
                  &.error {
                    color: $red;
                  }
                }
              }
              // .profileImageContainer {
              //   width: 250px;
              //   height: 250px;
              //   overflow: hidden;
              //   display: none;
              //   &.active {
              //     display: block;
              //   }
              // }
              .download {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 1em;
                right: 1em;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background-color: $text;
                color: $bg;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.3s ease-in-out, background-color 0.3s ease;
                &:hover {
                  cursor: pointer;
                  background-color: $green;
                  // transform: scale(1.1);
                }
                p {
                  padding-top: 0.25em;
                  font-size: 21px;
                  text-indent: 0;
                }
              }
              .help {
                position: absolute;
                bottom: 1em;
                right: 1em;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background-color: $text;
                color: $bg;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.3s ease-in-out, background-color 0.3s ease;
                &:hover {
                  cursor: pointer;
                  background-color: $subtext;
                  // transform: scale(1.1);
                }
                p {
                  padding-top: 0.25em;
                  font-size: 21px;
                  text-indent: 0;
                }
              }
              .mobileBlocker {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $grey;
                display: flex;
                justify-content: center;
                align-items: center;
                display: none;
                padding: 1.2em;
                z-index: 5;
                div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  svg {
                    position: relative;
                    width: 100px;
                    height: 100px;
                    fill: $red;
                    margin-bottom: 1.5em;
                  }
                  p {
                    position: relative;
                    font-family: $font-regular;
                    color: $red;
                    font-size: 16pt;
                    line-height: 1.2;
                    text-align: center;
                    text-indent: 0;
                  }
                }
                &.blocked {
                  display: block;
                }
              }
            }
            transition: all 500ms ease-in-out;
          }
          &.loading {
            transition: width 1000ms ease-in-out 500ms, height 1000ms ease-in-out 500ms,
              opacity 1000ms ease-in-out 1000ms;
          }

          &.loading-entering {
            transition: width 1000ms ease-in-out 500ms, height 1000ms ease-in-out 500ms,
              opacity 1000ms ease-in-out 1000ms;
            > .top {
              width: 72.44%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-self: flex-start;
              .line {
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-last-child(1) {
                  align-items: flex-end;
                  padding-bottom: 0.2em;
                }
                width: 100%;
                height: auto;
                font-size: 125px;
                line-height: 1;
              }
            }
            > .bottom {
              width: 100%;
              height: 65%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-self: center;
              text-align: right;
              > .caption {
                .left {
                  opacity: 0;
                  text-align: left;
                  padding-bottom: 0.35em;
                  text-indent: 2em;
                }
              }
              > .dnd {
                height: 100%;
                pointer-events: none;
                background-color: #c8c8c8;
                > .download {
                  visibility: hidden;
                  opacity: 0;
                }
                > .help {
                  opacity: 1;
                }
                > .dataCard {
                  width: 0;
                  height: auto;
                  padding: 2em;
                  pointer-events: auto;
                  display: grid;
                  grid-auto-flow: column;
                  grid-gap: 1.5em;
                  align-items: flex-end;
                  @include media("<=phone") {
                    display: none;
                  }
                  .portraitContainer {
                    position: relative;
                    visibility: hidden;
                    opacity: 0;
                    width: 600px;
                    height: 600px;
                    .fade {
                      // height: 100%;
                      // padding: 2em;
                      .react-p5 {
                        z-index: 5;
                        canvas {
                          width: 100% !important;
                          height: 100% !important;
                        }
                      }
                    }
                    .loadingContainer {
                      opacity: 0;
                    }
                  }

                  .infoContainer {
                    visibility: visible;
                    opacity: 0;
                    // padding: 2em;
                    .row {
                      width: 650px;
                      height: auto;
                      display: flex;
                      justify-content: center;
                      align-items: flex-start;
                      padding: 0.85em 0;
                      border-bottom: $text 1.5px solid;
                      &:nth-last-child(1) {
                        border-bottom: none;
                        padding: 0.85em 0 0 0;
                      }
                      p {
                        text-indent: 0;
                        text-align: left;
                      }
                      .left {
                        text-align: left;
                        width: 35%;
                      }
                      .right {
                        width: 65%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      }
                      .titleRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        p {
                          font-family: $font-medium;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.loading-entered {
            transition: width 1000ms ease-in-out 500ms, height 1000ms ease-in-out 500ms,
              opacity 1000ms ease-in-out 1000ms;
            // opacity: 1;
            > .top {
              // height: 0;
              // width: 0;
              opacity: 0;
            }
            > .bottom {
              height: auto;
              width: auto;
              > .caption {
                .left {
                  opacity: 1;
                  text-align: left;
                  padding-bottom: 0.35em;
                  text-indent: 2em;
                }
              }
              > .dnd {
                height: 100%;
                pointer-events: none;
                background-color: #c8c8c8;
                > .download {
                  visibility: visible;
                  opacity: 1;
                  pointer-events: auto;
                }
                > .help {
                  opacity: 0;
                  pointer-events: none;
                }
                > .container {
                  opacity: 0;
                  height: 0;
                  pointer-events: none;
                }
                > .dataCard {
                  width: 100%;
                  height: auto;
                  padding: 1.5em;
                  pointer-events: auto;
                  display: grid;
                  grid-auto-flow: column;
                  grid-gap: 1.5em;
                  align-items: flex-end;
                  .portraitContainer {
                    position: relative;
                    visibility: visible;
                    opacity: 1;
                    width: 600px;
                    height: 600px;
                    .fade {
                      position: relative;
                      // height: 100%;
                      // padding: 2em;
                      z-index: 5;
                      .react-p5 {
                        z-index: 5;
                        canvas {
                          z-index: 5;
                          width: 100% !important;
                          height: 100% !important;
                        }
                      }
                    }
                    .loadingContainer {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 75px;
                      height: auto;
                      z-index: 0;
                      opacity: 1;
                    }
                  }

                  .infoContainer {
                    visibility: visible;
                    opacity: 1;
                    // padding: 2em;
                    .row {
                      width: 650px;
                      height: auto;
                      display: flex;
                      justify-content: center;
                      align-items: flex-start;
                      padding: 0.85em 0;
                      border-bottom: $text 1.5px solid;
                      &:nth-last-child(1) {
                        border-bottom: none;
                        padding: 0.85em 0 0 0;
                      }
                      p {
                        text-indent: 0;
                        text-align: left;
                      }
                      .left {
                        text-align: left;
                        width: 35%;
                      }
                      .right {
                        width: 65%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      }
                      .titleRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        p {
                          font-family: $font-medium;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.loading-exiting {
            transition: width 1000ms ease-in-out 500ms, height 1000ms ease-in-out 500ms,
              opacity 1000ms ease-in-out 1000ms;
            // opacity: 0;
          }

          &.loading-exited {
            transition: width 1000ms ease-in-out 500ms, height 1000ms ease-in-out 500ms,
              opacity 1000ms ease-in-out 1000ms;
            > .top {
              width: 72.44%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-self: flex-start;
              .line {
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-last-child(1) {
                  align-items: flex-end;
                  padding-bottom: 0.2em;
                }
                width: 100%;
                height: auto;
                font-size: 125px;
                line-height: 1;
                @include media("<=phone") {
                  font-size: 54px;
                }
              }
            }
            > .bottom {
              width: 100%;
              height: 65%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-self: center;
              text-align: right;
              > .caption {
                @include media("<=phone") {
                  display: none;
                }
                .left {
                  opacity: 0;
                  text-align: left;
                  padding-bottom: 0.35em;
                  text-indent: 2em;
                }
              }
              > .dnd {
                height: 100%;
                background-color: #c8c8c8;
                > .help {
                  opacity: 1;
                }
                > .dataCard {
                  width: 0;
                  height: auto;
                  padding: 2em;
                  pointer-events: auto;
                  display: grid;
                  grid-auto-flow: column;
                  grid-gap: 1.5em;
                  align-items: flex-end;
                  @include media("<=phone") {
                    display: none;
                  }
                  .portraitContainer {
                    position: relative;
                    visibility: hidden;
                    opacity: 0;
                    .fade {
                      width: 0;
                      height: 0;
                      // height: 100%;
                      // padding: 2em;
                      .react-p5 {
                        z-index: 5;
                        canvas {
                          width: 100% !important;
                          height: 100% !important;
                        }
                      }
                    }
                    .loadingContainer {
                      opacity: 0;
                    }
                  }

                  .infoContainer {
                    visibility: hidden;
                    opacity: 0;
                    // padding: 2em;
                    @include media("<=phone") {
                      display: none;
                    }
                    .row {
                      width: 650px;
                      height: auto;
                      display: flex;
                      justify-content: center;
                      align-items: flex-start;
                      padding: 0.85em 0;
                      border-bottom: $text 1.5px solid;
                      &:nth-last-child(1) {
                        border-bottom: none;
                        padding: 0.85em 0 0 0;
                      }
                      @include media("<=phone") {
                        display: none;
                      }
                      p {
                        text-indent: 0;
                        text-align: left;
                      }
                      .left {
                        text-align: left;
                        width: 35%;
                      }
                      .right {
                        width: 65%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      }
                      .titleRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        p {
                          font-family: $font-medium;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .chapter {
        width: auto;
        position: relative;
        .number {
          font-size: 122px;
          font-family: $font-medium;
          line-height: 1;
          margin-bottom: -0.225em;
          @include media("<=phone") {
            font-size: 86px;
          }
        }
        .title {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 32px;
          line-height: 32px;
          // text-transform: uppercase;
          // transform: rotate(90deg);
          // transform-origin: center left;
          writing-mode: vertical-rl;
          text-orientation: mixed;
          font-family: $font-regular;
        }
      }
    }
    .inner-sm {
      padding: 1em;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2em;
      &:after {
        content: "";
        background: $text;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 96.5%;
        width: 1px;
      }
      .resources {
        max-width: 650px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        @include media("<=phone") {
          width: 80vw;
        }
        .pdf {
          width: 100%;
          height: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          margin-bottom: 0.5em;
          &:after {
            content: "";
            background: $text;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
          }
          .pdfCoverContainer {
            width: 185px;
            height: 250px;
            background-color: $subtext;
            margin-bottom: 1.5em;
            position: relative;
            background-size: contain;
            @include media("<=phone") {
              width: 125px;
              height: 175px;
            }
          }
          .desc {
            text-align: center;
            margin-bottom: 1.5em;
            line-height: 1.2;
            @include media("<=phone") {
              font-size: 16px;
            }
          }
          .buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-bottom: 2.5em;
            @include media("<=phone") {
              flex-direction: column;
            }
            a {
              text-decoration: none;
            }
            &:nth-last-child(1) {
              margin-right: 0;
            }
            .btn {
              padding: 0.725em 2.25em 0.5em 2.25em;
              border-radius: 50px;
              border: 1.5px solid $text;
              background-color: $bg;
              color: $text;
              font-size: 19px;
              margin-right: 1em;
              transition: all 0.35s ease-in-out;
              line-height: 1;
              @include media("<=phone") {
                margin: 0 0 1em 0;
                width: 300px;
                font-size: 16px;
                text-align: center;
              }
              &:hover {
                background-color: $text;
                color: $bg;
              }
            }
            .buy {
              background-color: $text;
              color: $bg;
              &:hover {
                border-color: $green;
                background-color: $green;
                // color: $text;
              }
            }
          }
        }
        .resourceLinks {
          width: 100%;
          height: 40%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 1.5em;
          .question {
            font-family: $font-semibold;
            margin-bottom: 1em;
            text-indent: 0;
          }
          p {
            margin-bottom: 0.5em;
            text-indent: 2.5em;
            line-height: 1.2;
            @include media("<=phone") {
              font-size: 16px;
            }
          }
          ol {
            font-size: 20px;
            margin: 0.5em 0 0 3.5em;
            list-style: decimal-leading-zero;
            text-align: left;
            @include media("<=phone") {
              font-size: 16px;
            }
            li {
              margin-bottom: 0.5em;
              &:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
            a {
              text-decoration: none;
              position: relative;
              display: inline-block;
              color: $text;
              text-indent: 0;
              &:after {
                content: "\2197";
                padding-left: 3px;
              }
              &:before {
                content: " ";
                position: absolute;
                left: 0;
                bottom: 0;
                width: calc(100% - 18px);
                height: 2px;
                background-color: $text;
                transition: width 0.35s ease-in-out;
              }
              &:hover {
                &:before {
                  width: 0;
                }
              }
            }
          }
        }
      }
      .social {
        // max-width: 650px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .share {
          width: 100%;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          margin-bottom: 0.5em;
          @include media("<=phone") {
            height: 60%;
            margin-bottom: 0;
          }
          &:after {
            content: "";
            background: $text;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
          }
          .shareCoverContainer {
            width: 150px;
            height: 100px;
            margin-bottom: 1em;
            position: relative;
            overflow: hidden;
          }
          .desc {
            font-family: $font-medium;
            max-width: 150px;
            text-align: center;
            margin-bottom: 1.5em;
            font-size: 24px;
            line-height: 1;
          }
          .buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 2.5em;
            a {
              text-decoration: none;
              margin-bottom: 2em;
              @include media("<=phone") {
                margin-right: 2.5em;
                &:nth-child(even) {
                  margin-right: 0;
                }
                &:nth-last-child(-n + 2) {
                  margin-bottom: 0;
                }
              }
            }
            &:nth-last-child(1) {
              margin-bottom: 0;
            }
            @include media("<=phone") {
              width: 50%;
              flex-direction: row;
              flex-wrap: wrap;
            }
            .btn {
              color: $text;
              font-size: 19px;
              transition: all 0.35s ease-in-out;
              line-height: 1;
              &:hover {
                transform: scale(1.15);
              }
              img {
                width: 40px;
                height: 40px;
              }
              @include media("<=phone") {
              }
            }
          }
        }
        .footerLinks {
          width: 100%;
          min-width: 300px;
          height: 30%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: row;
          margin-top: 1.5em;
          @include media("<=phone") {
            height: 40%;
            margin-top: 0;
          }
          .buttons {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 2.5em;
            a {
              width: 100%;
              text-decoration: none;
            }
            &:nth-last-child(1) {
              margin-bottom: 0;
            }
            .btn {
              min-width: 100%;
              padding: 0.725em 0 0.5em 0;
              border-radius: 50px;
              border: 1.5px solid $text;
              background-color: $bg;
              color: $text;
              font-size: 19px;
              margin-bottom: 0.8em;
              transition: all 0.35s ease-in-out;
              line-height: 1;
              text-align: center;
              &:hover {
                background-color: $text;
                color: $bg;
              }
              @include media("<=phone") {
                font-size: 18px;
              }
            }
            .donate {
              background-color: $text;
              color: $bg;
              &:hover {
                border-color: $green;
                background-color: $green;
                // color: $text;
              }
            }
            .error {
              &:hover {
                border-color: $red;
                background-color: $red;
                // color: $text;
              }
            }
          }
        }
      }
    }
  }
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  text-align: center;
  vertical-align: middle;
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  // box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: $text;
  color: $bg;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: $text;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}
